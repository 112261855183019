import React from "react"
import Layout from "../../components/layout"
import { graphql } from "gatsby"
import { ImageBox } from "../../components/box"
import GalleryLayout from "../../components/gallery-layout"

export default ({ data }) => (
  <Layout at={"gallery"} maxWidth={1200}>
    <GalleryLayout>
      {data.allFile.edges.map(({ node }) => (
        <ImageBox node={node} key={node.id} />
      ))}
    </GalleryLayout>
  </Layout>
)

export const query = graphql`
  query {
    allFile(
      filter: {
        extension: { regex: "/(jpg)/" }
        relativeDirectory: { eq: "images/gallery/doors" }
      }
    ) {
      edges {
        node {
          id
          relativePath
          childImageSharp {
            fluid(maxHeight: 510) {
              ...GatsbyImageSharpFluid
            }
            original {
              width
              height
            }
          }
        }
      }
    }
  }
`
